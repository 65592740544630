import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Flex } from '@chakra-ui/react'
import LinkWrapper from '../../../components/LinkWrapper'
// import BlockContent from "@sanity/block-content-to-react";
import { PortableText, PortableTextReactComponents } from '@portabletext/react'

import '../../../styles/styles.scss'

function OneColumnText(props) {
  const { _rawOneColumnText, image, withLink, imgLink, revertOrder, extra } = props
  /* DEFAULT STYLES */
  const defaultStyles = {
    fontColor: 'primary',
    fontSizes: {
      small: { base: '16px', md: '16px' },
      normal: { base: '18px', md: '18px' },
      h4: { base: '20px', md: '20px' },
      h3: { base: '22px', md: '22px' },
      h2: { base: '24px', md: '24px' },
      h1: { base: '26px', md: '26px' },
    },
    fontWeights: {
      normal: '400',
      strong: '600',
    },
    lineHeights: {
      small: { base: '28px', md: '28px' },
      normal: { base: '30px', md: '30px' },
      h4: { base: '32px', md: '32px' },
      h3: { base: '34px', md: '34px' },
      h2: { base: '36px', md: '36px' },
      h1: { base: '38px', md: '38px' },
    },
    letterSpacings: {
      small: { base: '0px', md: '0px' },
      normal: { base: '0px', md: '0px' },
      h4: { base: '0px', md: '0px' },
      h3: { base: '0px', md: '0px' },
      h2: { base: '0px', md: '0px' },
      h1: { base: '0px', md: 'opx' },
    },
    textAlign: { base: 'left', md: 'left' },
    mbSection: { base: '20px', md: '15px' },
    mbContainers: { base: '20px', md: '20px' },
    hover: { borderColor: 'primary' },
    linkFontStyle: 'italic',
    listsLeftMargin: { base: '30px', md: '60px' },
    listsRightMargin: { base: '20px', mds: '10px' },
    listItemBottomMargin: { base: '10px', mds: '10px' },
  }

  const styles = props.styles ? props.styles : defaultStyles

  const handleColor = () => {
    let myColor
    if (props.styles) {
      myColor = props.styles.fontColor
    } else {
      myColor = defaultStyles.fontColor
    }

    switch (myColor) {
      case 'primary':
        myColor = '#272158'
        break
      case '#272158':
        myColor = '#272158'
        break
      case 'teal':
        myColor = '#7fd5d1'
        break
      case '#7fd5d1':
        myColor = '#7fd5d1'
        break
      case 'white':
      case '#ffffff':
        myColor = '#fff'
        break
      case '#f8f8f8':
        myColor = '#f8f8f8'
        break
      default:
        myColor = '#272158'
        break
    }
    return myColor
  }

  const components = {
    marks: {
      link: ({ value, children }) => {
        const target = (value?.href || '').startsWith('http') ? '_blank' : undefined
        return (
          <a
            href={value?.href}
            target={target}
            rel={target === '_blank' ? 'noindex nofollow' : ''}
            className='blog-raw-content-a'
          >
            {children}
          </a>
        )
      },
    },
    listItem: {
      bullet: ({ children }) => (
        <li className='blog-raw-content-li' style={{ listStylePosition: 'inside' }}>
          {children}
        </li>
      ),
      number: ({ children }) => <li className='blog-raw-content-li'>{children}</li>,
      left: ({ children }) => <Box textAlign='left'>{children}</Box>,
      center: ({ children }) => <Box textAlign='center'>{children}</Box>,
      right: ({ children }) => <Box textAlign='right'>{children}</Box>,
    },
  } as Partial<PortableTextReactComponents>

  return (
    <Box w='100%' mb={styles.mbSection} display={revertOrder ? { base: 'block', md: 'flex' } : 'block'}>
      {image && (
        <Flex
          flexWrap={'wrap'}
          w={{ base: '100%', md: '50%', xl: '581px' }}
          maxH={!image ? 'auto' : { base: '458px' }}
          position='relative'
          justifyContent='center'
          mb={{ base: '20px', md: '0' }}
          float={revertOrder ? 'left' : 'right'}
          pr={revertOrder ? { base: '0', md: '38px' } : '0'}
          pl={revertOrder ? '0' : { base: '0', md: '38px' }}
        >
          {/* IMG WITHOUT LINK */}
          {!withLink && (
            <Flex maxW='100%' h='100%' position='relative'>
              <GatsbyImage image={image.asset.gatsbyImageData} alt={'wana blog image'} />
            </Flex>
          )}
          {/* IMG WITH LINK */}
          {withLink && (
            <LinkWrapper href={imgLink}>
              <Flex maxW='100%' h='100%' position='relative'>
                <GatsbyImage image={image.asset.gatsbyImageData} alt={'wana blog image'} />
              </Flex>
            </LinkWrapper>
          )}
        </Flex>
      )}

      <Box
        w={image && { base: '100%', md: '50%', xl: '50%' }}
        className={extra ? `blog-raw-content-cta` : 'blog-raw-content'}
        style={{ color: handleColor() }}
      >
        <PortableText value={_rawOneColumnText} components={components} />
      </Box>
    </Box>
  )
}

export default OneColumnText
